import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";

import FullSignupForm from "../components/newsletter/full-signup-form";
import PageContainer from "../components/layout/page-container";
import PageSection from "../components/layout/page-section";
import SupporterLogos from "../components/layout/supporter-logos";

export default ({ data }) => (
  <Layout>
    <PageContainer>
      <article>
        <PageSection background="dark-accent">
          <h1 className="page-title">About the Project</h1>
        </PageSection>
        <PageSection topPadding="md">
          <p>
            <b>{data.site.siteMetadata.short_title}</b> is an ongoing public history project presenting a cultural geography of music performance in Austin, Texas.
          </p>

          <h3>Directors</h3>
          <ul>
            <li>Michael Schmidt, <span className="lolite">Editorial Lead</span></li>
            <li>Brian Jones, <span className="lolite">Technical Lead</span></li>
          </ul>

          <h3>Contributors</h3>
          <ul>
            <li>Rae Mondo, <span className="lolite">Audio Editor</span></li>
            <li>Andrew Schmidt, <span className="lolite">Audio Engineer</span></li>
            <li>John Watson, <span className="lolite">Developer, Research Assistant</span></li>
          </ul>
        </PageSection>
      </article>
      <PageSection bottomPadding="lg">
        <SupporterLogos siteTitle={data.site.siteMetadata.title} />
      </PageSection>

      <PageSection background="gray" topPadding="md" bottomPadding="md">
        <FullSignupForm />
      </PageSection>

    </PageContainer>
  </Layout>
);

export const query = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
        short_title
      }
    }
  }
`